import React from "react"
import "./Layout.scss"

export default props => (
    <article className="layout">
        {props.heading ? props.heading : null}

        <section className="layout__grid">
            {props.filter ? (
                <section className="layout__grid____top">
                    {props.filter ? props.filter : null}
                </section>
            ) : null}
        </section>

        {props.gallery ? props.gallery : null}
    </article>
)
